import logo from "./logo.svg";
import "./App.css";
import React, { useState } from "react";

function App() {
  const [clickCount, setClickCount] = useState(0);

  const handleTitleClick = () => {
    setClickCount((prevCount) => {
      const newCount = prevCount + 1;
      if (newCount === 3) {
        window.location.href = "https://hinylon.com";
        return 0;
      }
      return newCount;
    });
  };

  return (
    <div>
      <div className="title" onClick={handleTitleClick}>
        Nobody
        <br />
        Labs<span className="blink">_</span>
      </div>
      <br />
      <br />
      <div className="subtitle">select-bodies building a better society</div>
    </div>
  );
}

export default App;
